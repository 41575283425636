/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import LightHeader from "./headers/lightHeader"

const Layout = ({ children }) => {
  const headerHeight = 32
  return (
    <>
      <LightHeader height={headerHeight} />
      <div className={`pt-${headerHeight}`}>{children}</div>
      <footer>
        <p>Footer here</p>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
