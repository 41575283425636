import Header from "./header"
import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import ThreeDots from "../threeDots"

const LightHeader = props => {
  const queryData = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "sqLogo.png" }) {
        childImageSharp {
          fluid(maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <div className="z-50 right-0 absolute">
        <ThreeDots />
      </div>
      <Header
        imageData={queryData}
        height={props.height}
        bannerColour="fakeTransparent"
        position="absolute"
      ></Header>
    </>
  )
}

export default LightHeader
